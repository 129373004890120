import { App } from './app.class';
import { Fetch } from '../fetch.class';
import { SlideModal } from './slidemodal.class';
import ClipboardJS from 'clipboard';

export class Page {

    constructor() {

        const $sectionHtml = document.querySelector("section#html");
        if($sectionHtml) {

            // Elemente
            const $btnSetup = $sectionHtml.querySelector("button.setup-connection");
            const $modalSetup = document.querySelector('#slidemodal-formsetup');
            const $btnCopy = $sectionHtml.querySelector("button.copy");
            const $btnDownload = $sectionHtml.querySelector("button.download");
            const $btnPanel = $sectionHtml.querySelector("button.panel");
            const $areaConnectionInfo = $sectionHtml.querySelector(".connection-info-area");
            const $areaConnectionReady = $sectionHtml.querySelector(".connection-ready-area");
            const $code = $sectionHtml.querySelector(".code");

            // Sendeverbindung einrichten
            if($modalSetup) {

                // Elemente
                const $form = $modalSetup.querySelector("form");
                const $btnSubmit = $form.querySelector("button[type=submit]");
                const $toastError = $modalSetup.querySelector(".toast.error");
                const $toastErrorText = $toastError.querySelector("p");

                // Status von Sende-Button aktualisieren
                const updateSubmitBtnStatus = function() {
                    $btnSubmit.disabled = !$form.checkValidity();
                };
                updateSubmitBtnStatus();

                // Änderungen an Formularangaben
                $form.addEventListener('change', event => {
                    updateSubmitBtnStatus();
                });
                $form.addEventListener('keyup', event => {
                    updateSubmitBtnStatus();
                });

                // Absenden
                $form.addEventListener('submit', event => {

                    event.preventDefault();
                    const $form = event.currentTarget;

                    // Sendebutton deaktivieren
                    App.setSubmitBtnLoading($form);

                    // Daten
                    const formData = new FormData($form);

                    // Fehlermeldung ausblenden
                    $toastError.classList.add("hidden");

                    // Senden
                    new Fetch().post(App.getUrl(App.getLang()+'/submit_formsetup'), formData)
                    .then(response => {

                        // Modal schliessen
                        window.SlideModals['slidemodal-formsetup'].close();

                        // Erfolgsanzeige
                        $btnPanel.dataset.href = response.form_panel_url
                        $btnDownload.dataset.href = response.sourcecode_download_url
                        $areaConnectionInfo.remove();
                        $areaConnectionReady.classList.remove("hidden");
                        const $newaccount = $areaConnectionReady.querySelector(".new-account");
                        if($newaccount) {
                            if(response.new_account != true)
                                $newaccount.remove();
                        }

                        // Sendeadresse in Code einfügen
                        const codeFragments = $code.querySelectorAll("code *");
                        codeFragments.forEach($fragment => {
                            if($fragment.textContent == '"SENDING ADDRESS"') {
                                $fragment.textContent = '"'+response.form_submission_url+'"';
                            }
                        });

                        // Conversion bei Google registrieren
                        window.gtag_report_conversion();

                        // Ziel bei Matomo triggern
                        if(response.new_account == true) {
                            _paq.push(['trackGoal', 4]);
                        }
                    })
                    .catch(error => {
                        $toastErrorText.innerHTML = error.message;
                        $toastError.classList.remove("hidden");
                    })
                    .finally(() => {

                        // Sendebutton zurücksetzen
                        App.setSubmitBtnActive($form);
                    });
                });
            }

            // Kopierfunktion
            const templateClipboard = new ClipboardJS($btnCopy, {
                text: function(trigger) {
                    const $code = document.querySelector("section#html .code > pre > code");
                    let text = $code.textContent.trim();
                    return text;
                }
            });
            templateClipboard.on('success', event => {
                event.clearSelection();
                const msg = event.trigger.dataset.successmsg ?? "Copied!";
                App.showMsg(msg, "success");
            });

            // Button Panel
            $btnPanel.addEventListener('click', e => {

                // URL aufrufen
                const url = $btnPanel.dataset.href;
                window.open(url, "_self");
            });

            // Button Download
            $btnDownload.addEventListener('click', e => {

                // URL aufrufen
                const url = $btnDownload.dataset.href;
                window.open(url, "_self");
            });
        }
    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('body').classList.contains('formsetup')) {
        new Page();
    }
});