// Imports
import { App } from "./website/app.class";
import { Fetch } from './fetch.class';
import { Modal } from './website/modal.class';
import { Switch } from './website/switch.class';
import ClipboardJS from 'clipboard';
import tippy from 'tippy.js';
import "@lottiefiles/lottie-player";

// Bereiche
import { Header } from "./website/header";
import { Footer } from "./website/footer";
import { Page as Page_Home } from "./website/page-home";
import { Page as Page_Plans } from "./website/page-plans";
import { Page as Page_Upgrade } from "./website/page-upgrade";
import { Page as Page_Formsetup } from "./website/page-formsetup";
import { Page as Page_Formrequest } from "./website/page-formrequest";
import { Page as Page_Product } from "./website/page-product";
import { Page as Page_Formtemplate } from "./website/page-formtemplate";

// Scroll-Progress
App.domready(() => {
    const $progress = document.querySelector("header .progress");
    if($progress) {

        const h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';

        window.addEventListener('scroll', event => {
            const percent = Math.round( (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100 );
            $progress.style.width = percent+"%";
        });
    }
});

// TOC Scroll-Indicator
App.domready(() => {
    const $toc = document.querySelector('.toc');
    if($toc) {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const id = entry.target.getAttribute('id');
                const $a = $toc.querySelector(`li > a[href="#${id}"]`);
                if($a) {
                    if (entry.intersectionRatio > 0) {
                        $toc.querySelectorAll('li.active').forEach($e => {
                            $e.classList.remove('active');
                        });
                        $a.parentElement.classList.add('active');
                    } else {
                        $a.parentElement.classList.remove('active');
                    }
                }
            });
        });
        document.querySelectorAll('main section[id], main section h3[id]').forEach(section => {
            observer.observe(section);
        });
    }
});

// Quelltext kopieren
App.domready(() => {

    const codeClipboard = new ClipboardJS('.code-with-copy > button', {
        target: function(trigger) {
            return trigger.nextElementSibling.firstChild;
        }
    });

    codeClipboard.on('success', event => {
        event.clearSelection();
        const msg = event.trigger.dataset.successmsg ?? "Copied!";
        App.showMsg(msg, "success");
    });

});

// Tooltips
App.domready(() => {
    tippy('button[data-tippy-content].tippy-dark', { theme: 'dark', trigger: 'mouseenter focus' });
    tippy('button[data-tippy-content].tippy-light', { theme: 'light', trigger: 'mouseenter focus' });
});

// FAQ Bereiche
App.domready(() => {

    // FAQs
    const $faqs = document.querySelectorAll("dl.faq > div");
    if($faqs.length > 0) {

        $faqs.forEach($faq => {

            const $btn = $faq.querySelector("button");
            const $dd = $faq.querySelector("dd");

            $btn.addEventListener('click', event => {

                // ist geöffnet
                if($faq.classList.contains('open')) {

                    $faq.classList.add('closing');
                    $btn.setAttribute('aria-expanded', 'false');

                    $dd.style.maxHeight = $dd.scrollHeight+"px";
                    setTimeout(() => {
                        $dd.style.maxHeight = "0px";
                    }, 1);
                }

                // geschlossen
                else {

                    $faq.classList.add('open');
                    $btn.setAttribute('aria-expanded', 'true');

                    $dd.style.maxHeight = "0px";
                    setTimeout(() => {
                        $dd.style.maxHeight = $dd.scrollHeight+"px";
                    }, 1);
                }
            });

            $dd.addEventListener('transitionend', event => {

                // wurde geschlossen
                if($faq.classList.contains('closing')) {
                    $faq.classList.remove('open');
                    $faq.classList.remove('closing');
                    $btn.setAttribute('aria-expanded', 'false');
                }

                // wurde geöffnet
                else if($faq.classList.contains('open')) {
                    $dd.style.maxHeight = '';
                }
            });
        });
    }
});

// Feedback Formular
App.domready(() => {

    const form_url = "https://form.taxi/s/cp6j50yp";

    const $feedbackForms = document.querySelectorAll("form.feedback");
    if($feedbackForms.length > 0) {
        $feedbackForms.forEach($form => {

            const $submitBtn = $form.querySelector("button[type=submit]");
            const $inputs = $form.querySelectorAll("input.inpt, textarea.inpt");

            // Status von Sendebutton aktualisieren
            const updateSubmitBtnStatus = function() {
                var isValid = true;
                $inputs.forEach($i => {
                    if($i.checkValidity() === false) {
                        isValid = false;
                    }
                });
                if(!isValid)
                    $submitBtn.setAttribute("disabled", "");
                else
                    $submitBtn.removeAttribute("disabled");
            };

            // Sendebutton aktivieren
            $inputs.forEach($input => {
                $input.addEventListener('keyup', event => {
                    updateSubmitBtnStatus();
                });
                $input.addEventListener('change', event => {
                    updateSubmitBtnStatus();
                });
            });
            updateSubmitBtnStatus();

            // Absenden
            $form.addEventListener('submit', event => {
                event.preventDefault();

                const $form = event.currentTarget;
                const formData = new FormData($form);

                // Sendebutton deaktivieren
                App.setSubmitBtnLoading($form);

                // Senden
                new Fetch().post(form_url, formData)
                .then(response => {

                    // Erfolgsmeldung anzeigen
                    const text = $form.getAttribute('data-msg-success');
                    App.showMsg(text, "success");

                    // Formular zurücksetzen
                    $form.reset();
                })
                .catch(error => {
                    App.showMsg(error.message ?? "Unknown error", "error", 5000);
                })
                .finally(() => {

                    // Sendebutton zurücksetzen
                    App.setSubmitBtnActive($form);
                });
            });
        });
    }
});

// Matomo
var _paq = window._paq = window._paq || [];
_paq.push(["setDoNotTrack", true], ["disableCookies"], ['trackPageView'], ['enableLinkTracking']);
App.domready(() => {

    const u = "https://matomo.form.taxi/";
    _paq.push(['setTrackerUrl', u+'matomo.php'], ['setSiteId', '1']);

    const $script = document.createElement('script');
    $script.setAttribute('async', true);
    $script.setAttribute('src', u+'matomo.js');

    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore($script, s);
});